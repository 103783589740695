<template>
  <div>
    <div class="text-patternTwo color-gray2">
      <p>
        Responsável:
        <span class="color-black4"> {{ userInfo.responsible }} </span>
      </p>
      <p>
        Aluno: <span class="color-black4"> {{ userInfo.student }} </span>
      </p>
      <p>
        Escola: <span class="color-black4"> {{ userInfo.school }} </span>
      </p>
      <b-form @submit.prevent="acceptConditionsAndLinkUser()">
        <b-form-group v-for="(term, termIndex) in terms" :key="termIndex">
          <b-form-checkbox
            :id="`checkbox-terms-${term.id}`"
            :value="true"
            :unchecked-value="false"
            v-model="formConditions[term.id]"
            class="text-patternTwo color-gray1"
            required
            @change="checkIfAllTermsIsAccepted()"
          >
            <span ref="checkboxRef" v-html="term.consent"> </span>
          </b-form-checkbox>
        </b-form-group>
        <error-message :error="feedbackGeneralError" />
        <b-form-group>
          <b-button
            type="submit"
            variant="secondary"
            size="lg"
            :disabled="!allTermsIsAccepted || loadingButton"
            ><b-spinner large v-if="loadingButton"></b-spinner
            ><span v-else>Avançar</span></b-button
          >
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingButton: false,
    userInfo: {},
    formConditions: {},
    allTermsIsAccepted: false,
    terms: [],
    feedbackGeneralError: {
      status: 0,
      message: [],
      errorDictionary: [],
      data: {},
    },
  }),
  methods: {
    acceptConditionsAndLinkUser() {
      this.loadingButton = true;
      let payload = {
        studentToken: localStorage.getItem('access_token'),
        consentOptions: [],
      };
      for (let o in this.formConditions) {
        let object = {
          id: parseInt(o),
          explicitlyAccept: this.formConditions[o],
        };
        payload.consentOptions.push(object);
      }

      this.$onboardingService
        .linkUser(payload)
        .then((response) => {
          this.$router.push('/choose-profile');
        })
        .catch((error) => {
          this.loadingButton = false;
          this.feedbackGeneralError = error;
        });
    },
    async getLinkConditions() {
      await this.$onboardingService
        .getConsents()
        .then((response) => {
          this.terms = response.data;
          this.terms.forEach((element) => {
            this.formConditions[element.id] = false;
          });
        })
        .catch((error) => {
          this.feedbackGeneralError = error;
        });
    },
    checkIfAllTermsIsAccepted() {
      this.allTermsIsAccepted = true;
      for (let o in this.formConditions)
        if (!this.formConditions[o]) {
          this.allTermsIsAccepted = false;
        }
    },
    getUserInfo() {
      let decodedAccountToken = this.$utilService.getDecodedToken(
        localStorage.getItem('account_token')
      );
      let decodedAccessToken = this.$utilService.getDecodedToken(
        localStorage.getItem('access_token')
      );

      this.userInfo = {
        responsible: decodedAccountToken.Name,
        student: decodedAccessToken.Name,
        school: decodedAccessToken.InstitutionName
      };
    },
  },
  async created() {
    this.getUserInfo();
    await this.getLinkConditions();
    if (this.terms.length > 0)
      this.$refs.checkboxRef.forEach((element) => {
        if (element.children.length > 0) {
          element.children[0].style = 'text-decoration: underline';
        }
      });
  },
};
</script>

<style lang="scss" scoped>
p,
form .form-group:not(:last-child) {
  text-align: left;
}

form {
  margin-top: 2.15rem;
  .form-group {
    .btn {
      margin-top: 3rem;
    }
    .custom-control.custom-checkbox {
      padding-left: 3.5rem;
      margin-bottom: 0.25rem;
    }
  }
}

@media (max-width: 768px) {
  form {
    margin-top: 1.5rem;
    .form-group {
      .custom-control.custom-checkbox {
        padding-left: 2.5rem;
      }
      .btn {
        margin-top: 0.75rem;
      }
    }
  }
}
</style>